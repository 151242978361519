<template>
  <div class="container">
  </div>
</template>

<script>

export default {
  name: "FavoritesObjects",
  mounted() {
    this.$router.push({name: 'FavoriteObjectsApartments'});
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "./style.scss";
</style>
